<template>
    <div id="content_public">
        <div>
            <rockiot-ui class="rockiot-element" type="gauge" variation="linear" orientation="horizontal" id="linear_1"
                value-color="000" serial="linear_1" name="" :min="minValue" :max="maxValue" :value="value"
                :units="featureName" size="md" click-action="test" text-color="#ff8800" scale-text-color="#000"
                bar-color="#111" svgwidth="200" progress-color="rgba(192, 192, 192)" control-bg="none" control-color="#888"
                zones="" scale="1" smallscale="1" needle="1" needle-color="#ff0000" background="#000" precision="5"
                ticks="2" :average="featureAvgValue"></rockiot-ui>
        </div>
        <div class="text-center mt-1">
            <h6>{{ prValue }}</h6>
        </div>
    </div>
</template>
<script>
export default {
    name: "RockiotChart",
    data() {
        return {
        };
    },
    props: {
        prValue: {
            type: String,
        },
        value: {
            type: Number,
            default: 5,
        },
        min: {
            type: Number,
            default: 0,
        },
        featureAvgValue: {
            type: Number,
            default: 150,
        },
        featureName: {
            type: String,
            default: "FeatureName777",
        }
    },
    computed: {
        maxValue() {
            if (this.value > this.featureAvgValue * 2)
                return this.value.toFixed(3)
            else return this.featureAvgValue.toFixed(3) * 2
        },
        minValue() {
            if (this.value > this.featureAvgValue * 2) {
                const calulateMin = this.min - (this.value - this.featureAvgValue * 2)
                return calulateMin.toFixed(3)
            }
            else return this.min.toFixed(3)
        }
    },



}
</script>