<template>
    <div class="ag-theme-alpine" style="height: 300px; display: flex; justify-content: center;">
        <AgGridVue :columnDefs="columnDefs" :rowData="rowData" style="width: 610px; height: 200px" class="ag-theme-alpine">
        </AgGridVue>
    </div>
</template>
  
<script>
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default {
    name: "Table",
    components: { AgGridVue },
    props: {
        columnDefs: {
            type: Array,
            default: () => [
                { headerName: 'Name', field: 'name' },
                { headerName: 'Birthday', field: 'birthday' },
                { headerName: 'Gender', field: 'gender' },
            ],
        },
        rowData: {
            type: Array,
            default: () => [
                { name: 'John Doe', birthday: 1999 / 7 / 28, gender: 'Man' }
            ],
        },
    },
    methods: {
        getRowStyle(params) {
            if (params.data.age > 28) {
                return { background: 'lightcoral' };
            } else {
                return null;
            }
        }
    }  
};
</script>