<template>
    <div v-if="chartType === 'Bar'">
        <BarChart v-bind:chart-data="chartData" v-bind:chart-options="chartOptions" v-bind:width="chartWidth"
            v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'Bubble'">
        <BubbleChart v-bind:chart-data="chartData" v-bind:chart-options="chartOptions" v-bind:width="chartWidth"
            v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'Doughnut'">
        <DoughnutChart v-bind:chart-data="chartData" v-bind:chart-options="chartOptions" v-bind:width="chartWidth"
            v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'Line'">
        <LineChart v-bind:chart-data="chartData" v-bind:chart-options="chartOptions" v-bind:width="chartWidth"
            v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'Pie'">
        <PieChart v-bind:chart-data="chartData" v-bind:chart-options="chartOptions" v-bind:width="chartWidth"
            v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'PolarArea'">
        <PolarAreaChart v-bind:chart-data="chartData" v-bind:chart-options="chartOptions" v-bind:width="chartWidth"
            v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'Radar'">
        <RadarChart v-bind:chart-data="chartData" v-bind:chart-options="chartOptions" v-bind:width="chartWidth"
            v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'Scatter'">
        <ScatterChart v-bind:chart-data="chartData" v-bind:chart-options="chartOptions" v-bind:width="chartWidth"
            v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'Speedometer'">
        <SpeedometerChart v-bind:feature-name="featureName" v-bind:is-segment-labels="isSegmentLabels"
            v-bind:feature-avg-value="featureAvgValue" v-bind:value="featureValue" v-bind:pr-value="prValue" />
    </div>
    <div v-else-if="chartType == 'ImageUrl'">
        <ImageChart v-bind:feature-name="featureName" v-bind:src="imageSrc" v-bind:height="chartHeight" />
    </div>
    <div v-else-if="chartType == 'ImageBase64'">
        <ImageChart v-bind:feature-name="featureName" v-bind:src="imageSrc" v-bind:height="chartHeight"
            v-bind:is-base64="true" />
    </div>
    <div v-else-if="chartType == 'Rockiot'">
        <RockiotChart v-bind:feature-name="featureName" v-bind:min="min" v-bind:feature-avg-value="featureAvgValue"
            v-bind:value="featureValue" v-bind:pr-value="prValue" />
    </div>
    <div v-else-if="chartType == 'Table'">
        <Table  v-bind:columnDefs="columnDefs" v-bind:rowData="rowData" />
    </div>
</template>


<script>
import BarChart from './chart/Bar.vue'
import BubbleChart from './chart/Bubble.vue'
import DoughnutChart from './chart/Doughnut.vue'
import LineChart from './chart/Line.vue'
import PieChart from './chart/Pie.vue'
import PolarAreaChart from './chart/PolarArea.vue'
import RadarChart from './chart/Radar.vue'
import ScatterChart from './chart/Scatter.vue'
import SpeedometerChart from './chart/Speedometer.vue'
import ImageChart from './chart/Image.vue'
import RockiotChart from './chart/Rockiot.vue'
import Table from './chart/Table.vue'
export default {
    name: 'cmpChart',
    components: {
        BarChart,
        BubbleChart,
        DoughnutChart,
        LineChart,
        PieChart,
        PolarAreaChart,
        RadarChart,
        ScatterChart,
        SpeedometerChart,
        ImageChart,
        RockiotChart,
        Table
    },
    props: {
        prValue: {//Speedometer使用，其他的須包含再chartData內
            type: String,
        },
        featureName: {
            type: String,
            default: "",
        },
        imageSrc: {
            type: String,
            default: "",
        },
        isSegmentLabels: {//決定是否顯示Speedometer的customSegmentLabels
            type: Boolean,
            default: false,
        },
        featureAvgValue: {//Speedometer使用，其他的須包含再chartData內
            type: Number,
            default: 500,
        },
        featureValue: {//Speedometer使用，其他的須包含再chartData內
            type: Number,
            default: 0,
        },
        chartType: {
            type: String,
            default: 'Doughnut',
            validator: function (value) {
                // 這個值必須是以下之一
                return ['Bar', 'Bubble', 'Doughnut', 'Line', 'Pie', 'PolarArea', 'Radar', 'Scatter', 'Speedometer', 'ImageUrl', 'ImageBase64', 'Rockiot', 'Table'].indexOf(value) !== -1
            }
        },
        chartData: {
            type: Object,
            default: () => { }
        },
        chartOptions: {
            type: Object,
            default: () => { }
        },
        chartWidth: {
            type: Number,
            default: 400
        },
        chartHeight: {
            type: Number,
            default: 400
        },
        min: {
            type: Number,
            default: 0
        },
        columnDefs: {
            type: Array,
            default: () => [
                { headerName: 'Name', field: 'name' },
                { headerName: 'Birthday', field: 'birthday' },
                { headerName: 'Gender', field: 'gender' },
            ],
        },
        rowData: {
            type: Array,
            default: () => [
                { name: 'John Doe', birthday: 1999/7/28, gender: 'Man' }
            ],
        },
    }

}
</script>