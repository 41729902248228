<template>
    <b-card class="row justify-content-center" border-variant="secondary" v-bind:header="title" align="center">
        <b-row no-gutters>
            <b-col class="col-8">
                <b-card-body>
                    <b-card-text>
                        {{scoreText}}
                    </b-card-text>
                </b-card-body>
            </b-col>
            <b-col class="col-4">
                <DoughnutChart v-bind:width="chartWidth" v-bind:height="chartHeight" v-bind:chartData="chartData">
                </DoughnutChart>
                <div class="text-center" style="position:relative; top: -60px;">{{ scoreValue }}%
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import DoughnutChart from './chart/Doughnut.vue'

export default {
    name: 'cmpAIScore',
    components: {
        DoughnutChart,
    },
    data() {
        return {
            chartData: {},
            scoreValue: this.score,
            scoreText:this.text
        };
    },
    props: {
        title: {
            type: String,
            default: 'AI Score'
        },
        text: {
            type: String,
            default: 'This is the score calculated by the machine learning model'
        },
        score: {
            type: Number,
            default: 0
        },
        chartOptions: {
            type: Object,
            default: () => { }
        },
        chartWidth: {
            type: Number,
            default: 100
        },
        chartHeight: {
            type: Number,
            default: 100
        },
    },
    created() {
        this.chartData = { datasets: [{ backgroundColor: ['rgba(54, 162, 235,0.7)', 'rgba(201, 203, 207,0.7)'], data: [this.scoreValue, 100 - this.scoreValue] }] }
    },


}
</script>