<template>
    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </template>
  
  <script>
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  )
  
  export default {
    name: 'LineChart',
    components: {
      LineChartGenerator
    },
    props: {
      chartId: {
        type: String,
        default: 'line-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 400
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      },
      chartData: {
      type: Object,
      default: () => {
        return {
            labels: [
            '2023/02/01',
            '2023/02/02',
            '2023/02/03',
            '2023/02/04',
            '2023/02/05',
            '2023/02/06',
            '2023/02/07'
          ],
          datasets: [
            {
              label: 'FeatureOne',
              backgroundColor: 'rgba(255, 99, 132,0.7)',
              data: [40, 39, 10, 40, 39, 80, 40]
            },
            {
              label: 'FeatureTwo',
              backgroundColor: 'rgba(54, 162, 235,0.7)',
              data: [20, 17, 23, 47, 51, 89, 21]
            }
          ]
        }
      },
    },
    chartOptions:{
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: {
                //把時間格式縮減
                callback: function (value, index, ticks) {
                  var characterLimit = 11;
                  var lable = this.getLabelForValue(value);
                  
                  if (lable.length >= characterLimit && ticks.length > 0) {
                    lable = lable.slice(0, lable.length).substring(0, characterLimit - 1).trim() + '...';
                    return lable;
                  }
                  return lable;
                }
              }
            }
          }
        }
      }
    }
    },
  }
  </script>
  