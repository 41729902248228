<template>
    <div>
        <div class="text-center mb-5">
            <h5>{{ featureName }}</h5>
        </div>
        <div class="d-flex justify-content-center" style="height: 100%;">
            <div class="my-auto">
                <vue-speedometer :width="width" :needleHeightRatio="needleHeightRatio" :value="value"
                    :currentValueText="currentValueText" :customSegmentLabels="isSegmentLabels ? customSegmentLabels : []"
                    :ringWidth="ringWidth" :needleTransitionDuration="needleTransitionDuration"
                    :needleTransition="needleTransition" :needleColor="needleColor" :textColor="textColor"
                    :maxValue="featureAvgValue * 2" />
            </div>
        </div>
        <div class="text-center mt-1">
            <h6>PR : {{ prValue }}</h6>
        </div>
    </div>
</template>
  
  
<script>
import VueSpeedometer from "vue-speedometer"

export default {
    name: "SpeedometerChart",
    components: { VueSpeedometer },
    props: {
        prValue: {
            type: Number,
            default: 0,
        },
        featureName: {
            type: String,
            default: "FeatureName777",
        },
        isSegmentLabels: {//決定是否顯示customSegmentLabels
            type: Boolean,
            default: false,
        },
        featureAvgValue: {
            type: Number,
            default: 500,
        },
        width: {
            type: Number,
            default: 400,
        },
        needleHeightRatio: {
            type: Number,
            default: 0.7,
        },
        value: {
            type: Number,
            default: 800,
        },
        currentValueText: {
            type: String,
            default: "Current Value: ${value}",
        },
        customSegmentLabels: {
            type: Array,
            default: () => [
                {
                    text: "Low",
                    position: "INSIDE",
                    color: "#555",
                    fontSize: "19px",
                },
                {
                    text: "Below avg",
                    position: "INSIDE",
                    color: "#555",
                    fontSize: "19px",
                },
                {
                    text: "Average",
                    position: "INSIDE",
                    color: "#555",
                    fontSize: "19px",
                },
                {
                    text: "Above avg",
                    position: "INSIDE",
                    color: "#555",
                    fontSize: "19px",
                },
                {
                    text: "High",
                    position: "INSIDE",
                    color: "#555",
                    fontSize: "19px",
                },
            ],
        },
        ringWidth: {
            type: Number,
            default: 47,
        },
        needleTransitionDuration: {
            type: Number,
            default: 3333,
        },
        needleTransition: {
            type: String,
            default: "easeElastic",
        },
        needleColor: {
            type: String,
            default: "#a7ff83",
        },
        textColor: {
            type: String,
            default: "#d8dee9",
        },
    },
};
</script>
  


<!-- 
//maxValue=featureAvgValue*2設計原因是為了讓正常人平均在中間
//customSegmentLabels' prop takes an array of 'CustomSegmentLabel' Object
type CustomSegmentLabel = {
  text?: string
  position?: OUTSIDE/INSIDE
  fontSize?: string
  color?: string
} -->

