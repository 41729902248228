<template>
    <div>
        <div class="text-center mb-3">
            <h5>{{ featureName }}</h5>
        </div>
        <div class="d-flex justify-content-center"
             :style="{ height: height + 'px', maxWidth: maxWidth + 'px', margin: '0 auto' }">
            <img :src="isBase64 ? dataUrl : src" :style="{ width: '100%', height: '100%', objectFit: objectFit }" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageChart',
    props: {
        isBase64: { //決定是否為base64格式
            type: Boolean,
            default: false,
        },
        featureName: {
            type: String,
            default: 'featureName',
        },
        src: {
            type: String,
            required: true,
        },
        height: {
            type: Number,
            default: 400,
        },
        maxWidth: {
            type: Number,
            default: 800,
        },
        objectFit: {
            type: String,
            default: 'contain',
            validator: function (value) {
                // 這個值必須是以下之一
                return ['contain', 'fill', 'cover', 'none', 'scale-down'].indexOf(value) !== -1
            }
        },
    },
    computed: {
        dataUrl() {
            if (this.src.startsWith('data:image')) {
                return this.src;
            }
            else {
                return `data:image/png;base64,${this.src}`;
            }
        },
    },
};
</script>

<!--
objectFit屬性是指定圖片如何進行內部放置的屬性，它可以控制圖片在容器中的大小和位置。

contain : 表示圖片會按比例縮放以充滿容器，並且保持圖片的寬高比不變。如果容器的高度或寬度比圖片的寬度或高度大，那麼圖片就會在容器中垂直居中並保持容器的背景顏色。如果容器的寬度和高度都比圖片小，那麼圖片就會按比例縮小，以充滿容器。
fill：圖片會被拉伸以充滿容器，這樣可能會扭曲圖片的寬高比。
cover：圖片會按比例縮放以充滿容器，並且圖片的某一邊會滿足容器的邊界，這樣可能會裁切圖片的某些部分。
none：圖片不會被縮放，保持原始大小，但是可能會超出容器範圍。
scale-down：圖片會按比例縮小以充滿容器，如果圖片的大小小於容器的大小，則保持原始大小。
-->
